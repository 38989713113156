import React from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const CodPriceTable = ({ className, prices, shipper, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const settings = {
        rows: prices,
        loading: false,
        colOrder: {
            order: 'desc',
            orderBy: 'price'
        },
        headCells: [
            { id: 'state_to', numeric: false, label: t('label.state_to'), search: true },
            { id: 'amount_from', numeric: true, label: t('label.amount_from'), search: true, render: (data) => { return data.amount_from + ' ' + data.symbol } },
            { id: 'amount_to', numeric: true, label: t('label.amount_to'), search: true, render: (data) => { return data.amount_to + ' ' + data.symbol }  },
            { id: 'price', numeric: true, label: t('label.price'), search: true, render: (data) => data.price + ' €' },
            { id: 'percentage', numeric: true, label: t('label.percentage'), search: true, render: (data) => `${parseFloat(data.percentage).toFixed(2)} %` },
            { id: 'payment_type', numeric: false, label: t('label.payment_type'), search: true },
            { id: 'delivery_type', numeric: false, label: t('label.delivery_type'), search: true },
        ],
    };

    if ( shipper.shortcut == 'Packeta')  {
        settings.headCells.splice(7, 0, { id: 'carrier_type', numeric: false, label: t('label.carrier_type'), search: true, render: (data) => data.carrier_type},);
    }

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default CodPriceTable;