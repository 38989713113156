import React, { useState } from 'react';
import {
    makeStyles
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import TabPanel from 'components/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PriceTable from './PriceTable';
import CodPriceTable from './CodPriceTable';
import InsurancePriceTable from './InsurancePriceTable';
import ServicePriceTable from './ServicePriceTable';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTab-root': {
            minWidth: 'initial',
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    appBar:{
        background: 'white'
    }
}));

export default function PricesTabs({ prices, tab, shipper, ...rest }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(tab);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar className={classes.appBar} position="static" color="default">
                <Tabs 
                    value={activeTab}
                    onChange={handleChange}
                    aria-label="scrollable auto tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab value="shipping_price" label={t('title.shipping_price')} />
                    <Tab value="cod_price" label={t('title.cod_price')} />
                    <Tab value="service_price" label={t('title.service_price')} />
                    <Tab value="insurance_prices" label={t('title.insurance_price')} />
                </Tabs>
            </AppBar>
            <TabPanel className={classes.tabPanel} value={activeTab} index={"shipping_price"}>
                <PriceTable shipper={shipper} prices={prices.prices ? prices.prices : []} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={activeTab} index={"cod_price"}>
                <CodPriceTable prices={prices.cod_prices ? prices.cod_prices : []} shipper={shipper}/>
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={activeTab} index={"service_price"}>
                <ServicePriceTable prices={prices.service_prices ? prices.service_prices : []} />
            </TabPanel>
            <TabPanel className={classes.tabPanel} value={activeTab} index={"insurance_prices"}>
                <InsurancePriceTable prices={prices.insurance_prices ? prices.insurance_prices : []} shipper={shipper} />
            </TabPanel>
        </div>
    );
}