import React, {useEffect, useState, useCallback, useContext, useRef} from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    makeStyles, Typography,
} from '@material-ui/core';
import ServerTable from 'components/ServerTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import CreditLogTableActions from './CreditLogTableActions';
import Alert from '@material-ui/lab/Alert';
import {formatDate, isAdmin, isUser, normalizeDate} from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {},
    body2_green: {
        color: 'green'
    },
    body2_red: {
        color: '#f44336'
    },
    checkbox: {
        padding: 0
    }
}));

const CreditLogTable = ({ className, user, packageId, refresh, ...rest }) => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState({ rows: [], count: 0 });
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [alertMessage, setAlertMessage] = useState(false);
    const tableFilter = useRef(null);

    const loadPackages = useCallback((pConditions = null) => {
        setLoading(true);
        if (!pConditions) {
            pConditions = tableFilter.current;
        }
        if (pConditions.created_at_from instanceof Date) {
            pConditions.created_at_from = normalizeDate(pConditions.created_at_from);
        }

        if (pConditions.created_at_to instanceof Date){
            pConditions.created_at_to = normalizeDate(pConditions.created_at_to);
        }

        if (packageId) {
            if (pConditions) {
                pConditions.package_id = packageId;
            }else{
                pConditions = { package_id: packageId };
            }
        }

        let params = pConditions ? new URLSearchParams(pConditions) : new URLSearchParams();

        if (user) {
            if (user.id) {
                params.set('user', user.id);
            }
            else{
                return;
            }
        }

        secureFetch( getApiRoute( isUser(appValues.user) ? 'creditlog' : 'admin_creditlog' ) + '?' + params ).then(json => {

            let checkBadCalculation = true;
            if (pConditions && (pConditions.operation || pConditions.reference_number)) {
                checkBadCalculation = false;
            }

            if (isAdmin(appValues.user) && checkBadCalculation) {
                //check bad credit calculations
                let result = json.rows.reduce(function (r, a) {
                    r[a.eshop] = r[a.eshop] || [];
                    r[a.eshop].push(a);
                    return r;
                }, Object.create(null));
    
                let findBadCalculations = false;
                for (const eshop in result) {
                    result[eshop].reverse();
                    for (let index = 0; index < result[eshop].length - 1; index++) {
                        if (result[eshop][index].credit_to !== result[eshop][index + 1].credit_from ) {
                            result[eshop][index].problem = true;
                            result[eshop][index + 1].problem = true;
                            findBadCalculations = true;
                            console.log(result[eshop][index])
                            console.log(result[eshop][index+1])
                        }
                    }
                }
                setAlertMessage(findBadCalculations);
            } else{
                setAlertMessage('');
            }

            setLoading(false);
            setRowsData(json);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureFetch, appValues.user, user, refresh, packageId])

    const exportData = [
        {
            'name': t('button.export_csv'),
            'url': getApiRoute('creditlog', ['export'], isAdmin(appValues.user)),
        },
        {
            'name': t('button.export_excel'),
            'url': getApiRoute('creditlog', ['export', 'excel'], isAdmin(appValues.user)),
        },
    ];

    if (isAdmin(appValues.user)) {
        exportData.push({
            'name': t('button.export_excelzip'),
            'url': getApiRoute('creditlog', ['export', 'excelzip'], isAdmin(appValues.user)),
        });
    }

    const settings = {
        rows: rowsData.rows,
        rowsLength: rowsData.count,
        loading: loading,
        colOrder: {
            order: 'desc',
            order_by: 'created_at'
        },
        refFilter: tableFilter,
        handleTableUpdate: loadPackages,
        headCells: [
            { id: 'id', numeric: false, label: t('label.id'), search: false, hide: true, disableOrder: true },
            { id: 'created_at', numeric: false, label: t('label.created_at'), search: true, searchType: 'dateRange', disableOrder: true, render: (data) => formatDate(data.created_at, true) },
            { id: 'message', numeric: false, label: t('label.message'), search: true, disableOrder: true },
            { id: 'reference_number', numeric: false, label: t('label.reference_number'), search: packageId ? false : true, disableOrder: true },
            { id: 'credit_from', numeric: true, label: t('label.credit_from'), search: false, disableOrder: true, render: (data) => {
                return data.credit_from ? parseFloat(data.credit_from).toFixed(2) + ' €' : null;
            } },
            { id: 'credit', numeric: true, label: t('label.credit'), search: false, disableOrder: true, render: (data) => {
                let cred = '';
                if (data.credit){
                    let color = classes.body2_red;
                    if(data.credit > 0){
                        cred = '+';
                        color = classes.body2_green;
                    }
                    cred += parseFloat(data.credit).toFixed(2) + ' €';
                    cred = <Typography className={color} variant='body2'>{cred}</Typography>;
                } else {
                    cred = null;
                }
                return cred;
            } },
            { id: 'credit_to', numeric: true, label: t('label.credit_to'), search: false, disableOrder: true, render: (data) => {
                return data.credit_to ? parseFloat(data.credit_to).toFixed(2) + ' €' : null;
            } },
            { id: 'actions', numeric: false, label: t('label.actions'), disableOrder: true, search: false, render: (data) => {
                return (
                    <CreditLogTableActions data={data} loadPackages={loadPackages}/>
                );
            } },
        ],
        addFilter: {
            operation: true,
        },
        rowClass: (data) => {
            if (data.problem) {
                return 'danger';
            }
        },
        exportData: exportData,
        loadFilterDate: {
            created_at_from : new Date(new Date().setDate(new Date().getDate() - 30)),
            created_at_to : new Date(),
        }
    };

    if ( isAdmin(appValues.user) && !user ) {
        settings.headCells.splice(2, 0, { id: 'user', numeric: false, label: t('label.eshop_name'), search: true, searchType: 'select', selectChoices: users, disableOrder: true, render: (data) => data.eshop });
    }
    useEffect(() => {
        if ( isAdmin(appValues.user) ) {
            secureFetch( getApiRoute( 'admin_user' ) ).then(json => {
                setUsers(json.map(user => {
                    return {
                        id: user.id,
                        name: user.eshop || ''
                    }
                }));
            })
        }
    }, [appValues.user, secureFetch]);


    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                {
                    !alertMessage ? null :
                    <Alert variant="filled" severity="error">
                        {t('messages.bad_calculations')}
                    </Alert>
                }
                <ServerTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default CreditLogTable;