import React, {useState, useContext, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    makeStyles,
    Chip
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import AppContext from 'context/AppContext';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { fuelSign } from 'shippers';
import {filterStates} from "../../../helpers";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
    root: {
    }
}));

const EditPriceForm = ({ className, data, pricelist, handleDialogOpenClose, loadPrices, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [values, setValues] = useState({
        [data.id]: {
            weight_from:    data.weight_from,
            weight_to:      data.weight_to,
            price:          data.price,
            percentage:     data.percentage,
            carrier_type:   data.carrier_type
        }
    });
    const [states, setStates] = useState({
        states_from: filterStates(appValues.states, data['state_from']),
        states_to: filterStates(appValues.states, data['state_to']),
    });

    const [toll, setToll] = useState(data.toll);
    const [fuel, setFuel] = useState(data.fuel);
    const [deliveryType, setDeliveryType] = useState(appValues.deliveryTypes.find(option => option.name === data.delivery_type).value);
    const [carrierType, setCarrierType] = useState(data.carrier_type !== null ? data.carrier_type : null);
    const [alertMessage, setAlertMessage] = useState('');
    const [carriers, setCarriers] = useState([]);

    useEffect(() => {
        if (pricelist.id && pricelist.shipper.shortcut === 'Packeta') {
            secureFetch(getApiRoute('admin_carrier_available')).then(json => {
                setCarriers(json);
            });
        }
    }, [pricelist]);

    const handleChange = (event, index) => {
        let tmpValues = values;
        tmpValues[index] = { ...tmpValues[index], [event.target.name]: event.target.value };
        setValues({ ...tmpValues });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if ( !states.states_from || states.states_from.length === 0 || !states.states_to || states.states_to.length === 0 ) {
            setAlertMessage(t('messages.states_not_blank'));
            return;
        }
        for (const key in values) {
            values[key].state_from = states.states_from.map(state => state.id);
            values[key].state_to = states.states_to.map(state => state.id);
            values[key].price_list = pricelist.id;
            values[key].toll = toll;
            values[key].fuel = fuel;
            values[key].delivery_type = deliveryType;
            values[key].carrier_type = carrierType;
        }
        secureFetch(getApiRoute('admin_price'), { prices: values }, 'PUT').then(json => {
            setAlertMessage('');
            handleDialogOpenClose();
            loadPrices();
        });
    };

    const rows = [];
    rows.push(
        <React.Fragment key={data.id} >
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label={t('label.weight_from')}
                    name="weight_from"
                    onChange={ (event) => { handleChange(event, data.id) } }
                    value={values[data.id] && values[data.id].weight_from ? values[data.id].weight_from : ''}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    }}
                    required
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label={t('label.weight_to')}
                    name="weight_to"
                    onChange={ (event) => { handleChange(event, data.id) } }
                    value={values[data.id] && values[data.id].weight_to ? values[data.id].weight_to : ''}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">kg</InputAdornment>,
                    }}
                    required
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    fullWidth
                    label={t('label.price')}
                    name="price"
                    onChange={(event) => { handleChange(event, data.id) }}
                    value={values[data.id] && values[data.id].price ? values[data.id].price : ''}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    required
                />
            </Grid>
            <Grid item xs={3}>
                <Box display="flex">
                    <TextField
                        fullWidth
                        label={t('label.percentage')}
                        name="percentage"
                        onChange={ (event) => { handleChange(event, data.id) } }
                        value={values[data.id] && values[data.id].percentage ? values[data.id].percentage : ''}
                        type="number"
                        inputProps={{ step: "0.001" }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                    />
                </Box>
            </Grid>
        </React.Fragment>
    );

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.edit_price')}
            </Typography>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={6}>
                    <Autocomplete
                        multiple
                        options={appValues.states}
                        onChange={ (event, pStates) => setStates({ ...states, states_from: pStates }) }
                        disableCloseOnSelect
                        value={states.states_from}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label={t('label.states_from')} placeholder={t('label.state')} />
                        )}
                        />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        multiple
                        options={appValues.states}
                        onChange={ (event, pStates) => setStates({ ...states, states_to: pStates }) }
                        disableCloseOnSelect
                        value={states.states_to}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label={t('label.states_to')} placeholder={t('label.state')} />
                        )}
                        />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('label.toll')}
                        name="toll"
                        onChange={(e) => setToll(e.target.value) }
                        value={toll}
                        type="number"
                        inputProps={{ step: "0.001" }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        label={t('label.fuel')}
                        name="fuel"
                        onChange={(e) => setFuel(e.target.value)}
                        value={fuel}
                        type="number"
                        inputProps={{ step: "0.001" }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{fuelSign(pricelist.shipper)}</InputAdornment>,
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel >{t('label.delivery_type')}</InputLabel>
                        <Select
                            value={deliveryType}
                            name="delivery_type"
                            onChange={(e) => setDeliveryType(e.target.value)}
                            required
                        >
                            {appValues.deliveryTypes.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                { deliveryType == 0 && carriers.length !== 0 &&
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel >{t('label.carrier_type')}</InputLabel>
                        <Select
                            value={carrierType}
                            name="carrier_type"
                            onChange={(e) => setCarrierType(e.target.value)}
                            required
                        >
                            {carriers.map((option) => (
                                <MenuItem
                                    key={option.packeta_id}
                                    value={option.packeta_id}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
                </Grid>
                {rows}
            </Grid>
            { 
                alertMessage === '' ? null : 
                <Box>
                    <Alert variant="filled" severity="error">
                        {alertMessage}
                    </Alert>
                </Box>
            }
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.edit')}
                </Button>
            </Box>
        </form>
    );
};

EditPriceForm.propTypes = {
    className: PropTypes.string
};

export default EditPriceForm;
