import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
    InputAdornment, FormLabel, RadioGroup, Radio, withStyles, Tooltip, Typography
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getErrorMessage, isInputError } from 'helpers';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
    Package as PackageIcon,
} from 'react-feather';
import getApiRoute from "../../../routes/apiRoutes";
import SecureFetchContext from "../../../context/SecureFetchContext";
import AppContext from "../../../context/AppContext";
import InfoIcon from "@material-ui/icons/Info";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        "&:before": {
            border: '1px solid #dadde9',
        },
        color: '#f5f5f9'
    },
}))(Tooltip);

const AdditionalnfoForm = ({ className, currencies, title, values, setValues, handleChange, handleCheckboxChange, errors, notAllowedServices, shipper, id, senderValues, setSenderValues, receiverValues, setReceiverValues, statesTo, setStatesTo, statesFrom, setStatesFrom, stateTo, setStateTo, stateFrom, setStateFrom, setCurrencies, reverse, setReverse, ...rest }) => {
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const { t } = useTranslation();

    const handleReverseChange = (event) => {
        const event_name = event.target.name;
        const event_value = event.target.value;

        let tmpValuesSender = {
            sender_city: receiverValues.receiver_city,
            sender_company: receiverValues.receiver_company,
            sender_email: receiverValues.receiver_email,
            sender_house_number: receiverValues.receiver_house_number,
            sender_name: receiverValues.receiver_name,
            sender_phone: receiverValues.receiver_phone,
            sender_state_code: event_value === 'from_sk' ? 'SK' : (event_value === 'to_sk' ? null : 'SK'),
            sender_street: receiverValues.receiver_street,
            sender_zip: receiverValues.receiver_zip,
        };

        if(reverse === 'no_reverse' || event_value === 'no_reverse'){
            setReceiverValues({
                ...receiverValues,
                receiver_city: senderValues.sender_city,
                receiver_company: senderValues.sender_company,
                receiver_email: senderValues.sender_email,
                receiver_house_number: senderValues.sender_house_number,
                receiver_name: senderValues.sender_name,
                receiver_phone: senderValues.sender_phone,
                receiver_state_code: event_value === 'to_sk' || (event_value === 'no_reverse' && !senderValues.sender_state_code) ? 'SK' : senderValues.sender_state_code,
                receiver_street: senderValues.sender_street,
                receiver_zip: senderValues.sender_zip,
            })
            setSenderValues({
                ...senderValues,
                sender_city: tmpValuesSender.sender_city,
                sender_company: tmpValuesSender.sender_company,
                sender_email: tmpValuesSender.sender_email,
                sender_house_number: tmpValuesSender.sender_house_number,
                sender_name: tmpValuesSender.sender_name,
                sender_phone: tmpValuesSender.sender_phone,
                sender_state_code: tmpValuesSender.sender_state_code,
                sender_street: tmpValuesSender.sender_street,
                sender_zip: tmpValuesSender.sender_zip,
            })
        } else {
            setReceiverValues({...receiverValues, receiver_state_code: event_value === 'to_sk' || (event_value === 'from_sk' && !senderValues.sender_state_code)? 'SK' : senderValues.sender_state_code});
            setSenderValues({...senderValues, sender_state_code: tmpValuesSender.sender_state_code});
        }

        setReverse(event_value);

        switch (event_value){
            case 'from_sk':
                secureFetch(getApiRoute('price_list_states', ['to', shipper.id])).then(json => {
                    const sk_state = appValues.states.find(s => s.code === 'SK');
                    secureFetch(getApiRoute('codlimit', [shipper.id, stateFrom ? stateFrom.id : sk_state.id])).then(codLimits => {
                        const sk_state = appValues.states.find(s => s.code === 'SK');
                        setStatesFrom([sk_state]);
                        setStatesTo(json);

                        let curr = codLimits.map((val) => val.currency);
                        setCurrencies(curr);

                        setStateTo(stateFrom || sk_state);
                        setStateFrom(sk_state);
                        setValues({ ...values, [event_name]: event_value !== 'no_reverse', 'cod_currency_code': curr[0] ? curr[0].code : values['cod_currency_code'] });
                    });
                })
                break;
            case 'to_sk':
                secureFetch(getApiRoute('price_list_states', ['from', shipper.id])).then(json => {
                    const sk_state = appValues.states.find(s => s.code === 'SK');
                    setStatesFrom(json.filter(state => state.code !== 'SK'));
                    setStatesTo([sk_state]);

                    setStateFrom(null);
                    setStateTo(sk_state);
                    setValues({ ...values, [event_name]: event_value !== 'no_reverse', 'cod_price': null, 'cod_currency_code': null });
                })
                break;

            default: // no reverse package
                secureFetch(getApiRoute('price_list_states', ['to', shipper.id])).then(json => {
                    const sk_state = appValues.states.find(s => s.code === 'SK');
                    secureFetch(getApiRoute('codlimit', [shipper.id, stateFrom ? stateFrom.id : sk_state.id])).then(codLimits => {
                        const sk_state = appValues.states.find(s => s.code === 'SK');
                        setStatesTo(json);
                        setStatesFrom([sk_state]);

                        let curr = codLimits.map((val) => val.currency);
                        setCurrencies(curr);

                        setStateTo(stateFrom || sk_state);
                        setStateFrom(sk_state);
                        setValues({ ...values, [event_name]: event_value !== 'no_reverse', 'cod_currency_code': curr[0] ? curr[0].code : values['cod_currency_code'] });
                    });
                });
        }
    }

    return (
        <Card style={{ height: '100%' }}>
            <CardHeader
                title={ title }
            />
            <Divider />
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            error={ isInputError(errors, 'reference_number') }
                            helperText={ getErrorMessage(errors, 'reference_number') }
                            fullWidth
                            label={t('label.reference_number')}
                            name={"reference_number"}
                            onChange={handleChange}
                            value={values["reference_number"] || ''}
                            required
                        />
                    </Grid>
                    {
                        ((['PACKETA'].includes(shipper.shortcut.toUpperCase()) && !id) || ['SPS', 'GLS', 'DPD', 'QDL'].includes(shipper.shortcut.toUpperCase())) &&
                        <Grid item xs={12}>
                            <TextField
                                error={ isInputError(errors, 'count_of_packages') }
                                helperText={ getErrorMessage(errors, 'count_of_packages') }
                                fullWidth
                                label={t('label.count_of_packages')}
                                name={"count_of_packages"}
                                type="number"
                                onChange={handleChange}
                                disabled={receiverValues['parcelshop'] !== null}
                                value={values["count_of_packages"] || 1}
                                InputProps={{
                                    startAdornment: <InputAdornment style={{opacity: 0.5}} position="start"><PackageIcon /></InputAdornment>,
                                }}
                            />
                        </Grid>
                    }
                    {
                        ['GLS', 'QDL', 'DPD'].includes(shipper.shortcut.toUpperCase()) &&
                        <Grid item xs={12}>
                            <TextField
                                error={ isInputError(errors, 'cod_reference') }
                                helperText={ getErrorMessage(errors, 'cod_reference') }
                                fullWidth
                                label={t('label.cod_reference')}
                                name={"cod_reference"}
                                onChange={handleChange}
                                value={values["cod_reference"] || ''}
                            />
                        </Grid>
                    }
                    <Grid item xs={7}>
                        <TextField
                            error={ isInputError(errors, 'cod_price') }
                            helperText={ getErrorMessage(errors, 'cod_price') }
                            fullWidth
                            label={t('label.cod_price')}
                            name={"cod_price"}
                            type="number"
                            onChange={handleChange}
                            value={values["cod_price"] || ''}
                            disabled={reverse === 'to_sk'}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Autocomplete
                            options={currencies}
                            value={ currencies.find(currency => currency.code === values['cod_currency_code']) || null }
                            onChange={ (event, currency) => currency ? setValues({ ...values, 'cod_currency_code': currency.code }) : null }
                            getOptionLabel={(option) => option.symbol}
                            renderInput={(params) => <TextField 
                                error={ isInputError(errors, 'cod_currency_code') }
                                helperText={ getErrorMessage(errors, 'cod_currency_code') }
                                {...params}
                                label={t('label.currency')}
                            />}
                        />
                    </Grid>
                    <Grid item sm={7} xs={12}>
                        <TextField
                            error={ isInputError(errors, 'insurance') }
                            helperText={ getErrorMessage(errors, 'insurance') }
                            fullWidth
                            label={t('label.insurance')}
                            name={"insurance"}
                            type="number"
                            onChange={handleChange}
                            value={values["insurance"] || ''}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">
                                    {shipper.shortcut.toUpperCase() === 'PACKETA' && values.cod_currency_code ? values.cod_currency_code : '€'}
                                </InputAdornment>,
                            }}
                        />
                    </Grid>
                    {
                        ['PACKETA', 'DPD'].includes(shipper.shortcut.toUpperCase()) &&
                        <Grid item sm={5} xs={12}>
                            <TextField
                                required={shipper.shortcut.toUpperCase() == 'PACKETA'}
                                error={isInputError(errors, 'weight')}
                                helperText={getErrorMessage(errors, 'weight')}
                                fullWidth
                                label={t('label.weight')}
                                name={"weight"}
                                type="number"
                                onChange={handleChange}
                                value={values["weight"] || ''}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                }}
                            />
                        </Grid>
                    }

                    {
                        shipper.shortcut.toUpperCase() !== 'PACKETA' &&
                        <Grid item xs={12}>
                            <FormControl required error={isInputError(errors, 'reverse')}>
                                <Grid container spacing={1} direction="row-reverse" justify="flex-start">
                                    <Grid item xs={6} >
                                        <FormLabel id="reverse-radio-buttons-group" style={{textAlign: "right"}}>{t('label.reverse')}</FormLabel>
                                    </Grid>
                                </Grid>
                                <RadioGroup
                                    name="reverse"
                                    value={reverse}
                                    onChange={handleReverseChange}
                                    aria-labelledby="reverse-radio-buttons-group"
                                    style={{width: "100%"}}
                                >
                                    <Grid container spacing={0} direction="row-reverse" justify="flex-start">
                                        <Grid item xs={6}>
                                            <FormControlLabel value="from_sk" control={<Radio
                                                disabled={notAllowedServices.includes('reverse') || receiverValues['parcelshop'] !== null}
                                            />} label={
                                                <Typography variant="body1" style={{position: "relative"}}>
                                                    {t("label.from_slovakia")}
                                                    <HtmlTooltip title={<Typography variant="body1" dangerouslySetInnerHTML={{__html: t("tooltip.reverse_order_from_slovakia")}} />} arrow>
                                                        <InfoIcon fontSize="small" style={{position: "absolute", left: "104%"}}/>
                                                    </HtmlTooltip>
                                                </Typography>
                                            }/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value="no_reverse" control={<Radio
                                                disabled={notAllowedServices.includes('reverse') || receiverValues['parcelshop'] !== null}
                                            />} label={t("label.standard_package")} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel value="to_sk" control={<Radio
                                                disabled={notAllowedServices.includes('reverse') || receiverValues['parcelshop'] !== null}
                                            />} label={
                                                <Typography variant="body1" style={{position: "relative"}}>
                                                    {t("label.from_abroad")}
                                                    <HtmlTooltip title={<Typography variant="body1" dangerouslySetInnerHTML={{__html: t("tooltip.reverse_order_to_slovakia")}} />} arrow>
                                                        <InfoIcon fontSize="small" style={{position: "absolute", left: "104%"}}/>
                                                    </HtmlTooltip>
                                                </Typography>
                                            }/>
                                        </Grid>
                                    </Grid>
                                </RadioGroup>
                                <FormHelperText>{getErrorMessage(errors, 'reverse')}</FormHelperText>
                            </FormControl>
                        </Grid>
                    }
                    {
                        shipper.shortcut.toUpperCase() !== 'PACKETA' &&
                        <React.Fragment>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!values.save_address}
                                            onChange={handleCheckboxChange}
                                            name="save_address"
                                            value="1"
                                            color="primary"
                                            disabled={receiverValues['parcelshop'] !== null}
                                        />
                                    }
                                    label={t('label.save_address')}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};

AdditionalnfoForm.propTypes = {
    className: PropTypes.string,
    currencies: PropTypes.array
};

export default AdditionalnfoForm;
