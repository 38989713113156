import React, { useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    Button,
    Box,
    Tooltip,
    makeStyles, CardHeader
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import DeleteIconDialog from 'components/DeleteIconDialog';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EditCarrierForm from "../Shipper/ShipperEditView/EditCarrierForm";

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const CarriersTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [openEditCarrierDialog, setOpenEditCarrierDialog] = React.useState(false);
    const [editCarrierData, setEditCarrierData] = useState({});

    const load = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('admin_carrier') ).then(json => {
            setRows(json);
            setLoading(false);
        });
    }, [secureFetch])

    const handleEditDialogOpenClose = (data) => {
        if(openEditCarrierDialog) {
            setOpenEditCarrierDialog(false);
        } else {
            setEditCarrierData(data);
            setOpenEditCarrierDialog(true);
        }
    };

    useEffect(() => {
        load();
    }, [load]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'packeta_id'
        },
        headCells: [
            { id: 'packeta_id', numeric: false, label: t('label.id'), search: true },
            { id: 'name', numeric: false, label: t('label.name'), search: true },
            { id: 'state', numeric: false, label: t('label.state'), search: true },
            { id: 'currency', numeric: false, label: t('label.currency'), search: false },
            { id: 'available', numeric: false, label: t('label.enabled'), search: false , render: (row) => {
                    return (
                        row.available ? <CheckIcon/> : <ClearIcon/>
                    );
                } },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, render: (data) => {
                    return (
                        <Box display="flex">
                            <Tooltip title={t('tooltip.edit')} aria-label={t('tooltip.edit')} onClick={() => {handleEditDialogOpenClose(data)}}>
                                <IconButton
                                    size="small"
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <DeleteIconDialog
                                text={
                                    <React.Fragment>
                                        {t('messages.really_delete')}
                                        <br/>
                                        <pre>
                                        {JSON.stringify(data, null, 2)}
                                    </pre>
                                    </React.Fragment>
                                }
                                route={getApiRoute('admin_carrier', [data.id])}
                                onDelete={load}
                            />
                        </Box>
                    );
                } },
        ],
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>

            <Dialog
                open={openEditCarrierDialog}
                onClose={handleEditDialogOpenClose}
                maxWidth="md"
            >
                <DialogContent>
                    <EditCarrierForm carrier={editCarrierData} handleEditDialogOpenClose={handleEditDialogOpenClose} load={load} />
                </DialogContent>
            </Dialog>
        </Card>
    );
}

export default CarriersTable;