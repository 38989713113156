import React, { useState, useCallback, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import {
    Card,
    CardContent,
    IconButton,
    Tooltip,
    makeStyles
} from '@material-ui/core';
import ClientTable from 'components/ClientTable';
import SecureFetchContext from 'context/SecureFetchContext';
import {
    Package as PackageIcon
} from 'react-feather';
import getApiRoute from 'routes/apiRoutes';
import AppContext from 'context/AppContext';
import {useNavigate} from 'react-router-dom';
import {getShipperIcon} from 'helpers';
import { NavLink as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {}
}));

const ParcelshopTable = ({ className, ...rest }) => {
    const classes = useStyles();
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    let navigate = useNavigate();

    const loadParcelshops = useCallback(() => {
        setLoading(true);
        secureFetch( getApiRoute('parcelshop_list') ).then(json => {
            if (appValues.user.roles.includes('ROLE_USER')) {
                let shippers = [];
                for (const v of appValues.user.user_shipper_price_lists) {
                    shippers.push(v.shipper.shortcut);
                }
                setRows(json.filter(parcel => shippers.includes(parcel.shipper)));
            } else {
                setRows(json);
            }
            setLoading(false);
        })
    }, [secureFetch, appValues.user])

    const getUniqStatesCode = () => {
        let statesCodes = rows.map((el) => {
            return { id: el.state_code.toLowerCase(), name: el.state_code }
        })

        return statesCodes.filter( (el, index) => 
            statesCodes.findIndex( obj => obj.id === el.id ) === index
        );
    };

    useEffect(() => {
        loadParcelshops();
    }, [loadParcelshops]);

    const settings = {
        rows: rows,
        loading: loading,
        colOrder: {
            order: 'asc',
            orderBy: 'city'
        }, 
        headCells: [
            { id: 'shipper', numeric: false, label: t('label.shipper'), search: true, render: (data) => getShipperIcon(data) },
            { id: 'parcelshop_id', numeric: false, label: t('label.id'), search: true },
            { id: 'name', numeric: false, label: t('label.title'), search: true },
            { id: 'street', numeric: false, label: t('label.street'), search: true },
            { id: 'zip', numeric: false, label: t('label.zip'), search: true },
            { id: 'city', numeric: false, label: t('label.city'), search: true },
            { id: 'state_code', numeric: false, label: t('label.state'), search: true, searchType: 'select', selectChoices: getUniqStatesCode() },
            { id: 'actions', numeric: false, label: t('label.actions'), search: false, hide: appValues.user.roles.includes('ROLE_ADMIN'), render: (data) => {
                return (
                    <Tooltip title={t('tooltip.new_package')} aria-label={t('tooltip.new_package')}>
                        <IconButton
                            color="inherit"
                            component={RouterLink}
                            to={"/app/package/newparcelshop/" + data.shipper_id + '/' + data.id}
                            onClick={null}
                        >
                            <PackageIcon />
                        </IconButton>
                    </Tooltip>
                );
            } },
        ],
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardContent>
                <ClientTable settings={settings} />
            </CardContent>
        </Card>
    );
}

export default ParcelshopTable;