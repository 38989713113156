const SERVER = process.env.REACT_APP_API_URL;
const routes = {
    login: '/login_check',
    refresh_token: '/token/refresh',
    edit_password: '/user/editpassword',
    user: '/user/',
    logo: '/user/logo',
    email: '/email/template/',
    email_templates: '/email/template/list/',
    states: '/states',
    parcelshop: '/all/parcelshop/',
    parcelshop_list: '/all/parcelshop/list/',
    nearest_parcelshops: '/all/parcelshop/nearest/',
    currencies: '/currency',
    shippers: '/shipper/',
    services: '/service/list/',
    admin_user: '/admin/user/',
    admin_edit_password: '/admin/user/editpassword/',
    admin_shipper: '/admin/shipper/',
    admin_partnercommission: '/admin/partnercommission/',
    admin_statistics: '/admin/statistics/',
    admin_pricelist_list: '/admin/pricelist/list',
    admin_pricelist: '/admin/pricelist/',
    admin_pricelist_users: '/admin/pricelist/users/',
    admin_pricelist_fuel: '/admin/pricelist/fuel',
    admin_price: '/admin/price/',
    admin_price_list: '/admin/price/list/',
    admin_codprice: '/admin/codprice/',
    admin_codprice_list: '/admin/codprice/list/',
    admin_insuranceprice: '/admin/insuranceprice/',
    admin_insuranceprice_list: '/admin/insuranceprice/list/',
    admin_serviceprice: '/admin/serviceprice/',
    admin_serviceprice_list: '/admin/serviceprice/list/',
    admin_service_list: '/admin/service/list/',
    admin_service: '/admin/service/',
    admin_service_neoship: '/admin/service/neoship/',
    admin_user_pricelists: '/admin/user/pricelists/',
    admin_user_shippersettings: '/admin/user/shippersettings/',
    admin_shipper_pricelists: '/admin/shipper/pricelists/',
    admin_package: '/admin/package/',
    admin_package_service: '/admin/package/service',
    admin_package_unpaid: '/admin/package/unpaid',
    admin_package_paid: '/admin/package/paid',
    admin_creditlog: '/admin/creditlog/',
    admin_package_services: '/admin/package/services/',
    admin_package_reference_number: '/admin/package/referencenumber/',
    admin_status: '/admin/status/',
    admin_statustype: '/admin/statustype/',
    admin_message: '/admin/message/',
    admin_email: '/admin/user/email/',
    admin_email_templates: '/admin/user/email/template/list/',
    admin_package_calculate: '/admin/package/calculate/',
    admin_package_calculateprice: '/admin/package/calculateprice/',
    admin_package_prices: '/admin/package/prices/',
    admin_codlimit: '/admin/codlimit/',
    admin_logo: '/admin/user/logo/',
    admin_iban: '/admin/iban/',
    admin_carrier: '/admin/carrier/',
    admin_carrier_available: '/admin/carrier/available/',
    carrier_available: '/carrier/available/',
    partner_statistics: '/partner/statistics/',
    partner_user: '/partner/user/',
    creditlog: '/creditlog/',
    package: '/package/',
    package_service: '/package/service',
    package_prices: '/package/prices/',
    package_bulk: '/package/bulk/',
    package_reference_number: '/package/referencenumber/',
    package_unpaid: '/package/unpaid',
    package_paid: '/package/paid',
    package_new: '/package/',
    package_services: '/package/services/',
    package_calculate: '/package/calculate/',
    log: '/log/',
    admin_log: '/admin/log/',
    price_list: '/pricelist/',
    price_list_states: '/pricelist/states/',
    statustype: '/statustype/',
    message: '/message/',
    message_unread: '/message/unread/',
    statistics: '/statistics/',
    usersettings: '/usersettings/',
    user_shippersettings: '/usersettings/shippersettings/',
    tracking: '/all/tracking/',
    credit_charge: '/credit/charge',
    credit_charge_comgate: '/credit/charge/comgate',
    credit_charge_besteron2: '/credit/charge/besteron2',
    import: '/package/import/',
    codlimit: '/codlimit/',
    run_command: '/run/command/neoship/',
    address_list: '/address/list',
    address_new: '/address/new',
    address: '/address/',
    connector_settings: '/connector/settings/',
    admin_address_list: '/admin/addresslist/',
    extended_statustype: '/statustype/extended/',
    cod_sum: '/package/cod_sum/',
    depot_contacts: '/usersettings/depotcontacts/',
    user_service_neoship: '/service/neoship/list/',
    admin_user_discount: '/admin/discount/',
    user_discount: '/discount/',
    admin_usersettings_findico: '/admin/usersettings/findico/',
    admin_usersettings_findiban: '/admin/usersettings/findiban/',
    admin_comgate_verify: '/admin/creditlog/comgate/verify/',
    admin_besteron_verify: '/admin/creditlog/besteron/verify/',
    admin_return_payment: '/admin/creditlog/return/',
    sticker_balance: '/package/balance/',
    microcampaign_settings: '/usersettings/microcampaign/',
    price_list_carrier: '/pricelist/carrier/',
    iban: '/iban/'
};

const getApiRoute = ( name, params = [], isAdmin ) => {
    if (isAdmin) {
        return SERVER + '/api/admin' + routes[name] + params.join('/');
    }
    return SERVER + '/api' + routes[name] + params.join('/');
};

export const getUploadPath = ( key='' ) => {
    return process.env.REACT_APP_LOGO_URL + '/uploads/' + key;
};

export const getLogoPath = ( logo ) => {
    return getUploadPath('logo/') + logo;
};

export default getApiRoute;
