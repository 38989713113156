import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    TextField,
    Grid,
    makeStyles, Typography, Checkbox, Chip, FormControlLabel
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
    root: {}
}));

const EditCarrierForm = ({ className, carrier, handleEditDialogOpenClose, load, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [values, setValues] = useState({});

    const handleCheckboxChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.checked });
    };

    useEffect(() => {
        if ( carrier.name ) {
            setValues({
                available: carrier.available,
            });
        }
    }, [carrier]);

    const handleSubmit = (event) => {
        event.preventDefault();
        secureFetch(getApiRoute('admin_carrier', [carrier.id]), values, 'PUT').then(json => {
            handleEditDialogOpenClose();
            load();
        });
    };

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.edit')}
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom >
                        {carrier.name + ' - ' + carrier.packeta_id}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.available ? true : false}
                                onChange={handleCheckboxChange}
                                name="available"
                                color="primary"
                            />
                        }
                        label={t('label.enabled')}
                    />
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleEditDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.save')}
                </Button>
            </Box>

        </form>
    );
};

EditCarrierForm.propTypes = {
    className: PropTypes.string
};

export default EditCarrierForm;
