import React, {useState, useContext, useEffect} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    makeStyles, Chip
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import Alert from '@material-ui/lab/Alert';
import AppContext from 'context/AppContext';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { filterStates } from "../../../helpers";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() => ({
    root: {
    }
}));

const EditInsurancePriceForm = ({ className, data, pricelist, handleDialogOpenClose, loadPrices, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    const [values, setValues] = useState({
        [data.id]: {
            amount_from:    data.amount_from,
            amount_to:      data.amount_to,
            price:          data.price,
            percentage:     data.percentage,
            carrier_type:   data.carrier_type
        }
    });
    const [states, setStates] = useState({states_to: filterStates(appValues.states, data['state_to'])});
    const [alertMessage, setAlertMessage] = useState('');
    const [currency, setCurrency] = useState(appValues.currencies.find(c => c.symbol === data.symbol));
    const [carriers, setCarriers] = useState([]);

    useEffect(() => {
        if (pricelist.id && pricelist.shipper.shortcut === 'Packeta') {
            secureFetch(getApiRoute('admin_carrier_available')).then(json => {
                setCarriers(json);
            });
        }
    }, [pricelist]);

    const handleChange = (event, index) => {
        let tmpValues = values;
        tmpValues[index] = { ...tmpValues[index], [event.target.name]: event.target.value };
        setValues({ ...tmpValues });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if ( !states.states_to || states.states_to.length === 0 ) {
            setAlertMessage(t('messages.states_not_blank'));
            return;
        }
        for (const key in values) {
            values[key].state_to = states.states_to.map(state => state.id);
            values[key].currency = currency.id;
            values[key].price_list = pricelist.id;
        }
        secureFetch(getApiRoute('admin_insuranceprice'), { prices: values }, 'PUT').then(json => {
            setAlertMessage('');
            handleDialogOpenClose();
            loadPrices();
        });
    };

    const rows = [];
    rows.push(
        <React.Fragment key={data.id} >
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label={t('label.amount_from')}
                    name="amount_from"
                    onChange={ (event) => { handleChange(event, data.id) } }
                    value={values[data.id] && values[data.id].amount_from ? values[data.id].amount_from : ''}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    required
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    fullWidth
                    label={t('label.amount_to')}
                    name="amount_to"
                    onChange={ (event) => { handleChange(event, data.id) } }
                    value={values[data.id] && values[data.id].amount_to ? values[data.id].amount_to : ''}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    required
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    label={t('label.price')}
                    name="price"
                    onChange={ (event) => { handleChange(event, data.id) } }
                    value={values[data.id] && values[data.id].price ? values[data.id].price : ''}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    required
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    fullWidth
                    label={t('label.percentage')}
                    name="percentage"
                    onChange={ (event) => { handleChange(event, data.id) } }
                    value={values[data.id] && values[data.id].percentage ? values[data.id].percentage : ''}
                    type="number"
                    inputProps={{ step: "0.001" }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    required
                />
            </Grid>
            <Grid item xs={6}>
                { carriers.length !== 0 &&
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel >{t('label.carrier_type')}</InputLabel>
                        <Select
                            value={values[data.id] && values[data.id].carrier_type != null ? values[data.id].carrier_type : ''}
                            name="carrier_type"
                            onChange={(event) => { handleChange(event, data.id)} }
                            // required
                        >
                            {carriers.map((option) => (
                                <MenuItem
                                    key={option.packeta_id}
                                    value={option.packeta_id}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                }
            </Grid>
        </React.Fragment>
    );

    return (
        <form
            autoComplete="off"
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
        >
            <Typography variant="h3" gutterBottom >
                {t('title.edit_price')}
            </Typography>
            <Grid container spacing={3} alignItems="center">
                <Grid item xs={6}>
                    <Autocomplete
                        multiple
                        options={appValues.states}
                        onChange={ (event, pStates) => setStates({ ...states, states_to: pStates }) }
                        disableCloseOnSelect
                        value={states.states_to}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label={t('label.states_to')} placeholder={t('label.state')} />
                        )}
                        />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        options={appValues.currencies}
                        onChange={(event, pCurrency) => setCurrency(pCurrency)}
                        value={currency}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label={t('label.currency')} required />}
                    />
                </Grid>
                {rows}
            </Grid>
            { 
                alertMessage === '' ? null : 
                <Box>
                    <Alert variant="filled" severity="error">
                        {alertMessage}
                    </Alert>
                </Box>
            }
            <Box display="flex" justifyContent="flex-end" py={2}>
                <Button color="secondary" variant="text" onClick={handleDialogOpenClose} >
                    {t('button.close')}
                </Button>
                <Button color="primary" variant="contained" type="submit">
                    {t('button.edit')}
                </Button>
            </Box>
        </form>
    );
};

EditInsurancePriceForm.propTypes = {
    className: PropTypes.string
};

export default EditInsurancePriceForm;
