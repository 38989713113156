import React, { useContext, useState, useEffect } from 'react';
import {
    Container,
    Grid,
    Typography,
} from '@material-ui/core';
import Page from 'components/Page';
import { useTranslation } from "react-i18next";
import AddressForm from '../PackageNewEditView/AddressForm';
import AdditionalnfoForm from '../PackageNewEditView/AdditionalnfoForm';
import ServicesForm from '../PackageNewEditView/ServicesForm';
import AppContext from 'context/AppContext';
import { useParams } from "react-router-dom";
import SecureFetchContext from 'context/SecureFetchContext';
import getApiRoute from 'routes/apiRoutes';
import { useNavigate } from "react-router-dom";
import {createUrlQuery, getDefaultAddress} from 'helpers';
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

export default function PackageEditView() {
    const { t } = useTranslation();
    const { id, shipper, parcelshop_id , address_id} = useParams();
    const secureFetch = useContext(SecureFetchContext);
    const { appValues } = useContext(AppContext);
    let navigate = useNavigate();
    const { state } = useLocation();
    const [senderValues, setSenderValues] = useState({
        sender_name: appValues.user.address ? appValues.user.address.name : null,
        sender_company: appValues.user.address ? appValues.user.address.company : null,
        sender_street: appValues.user.address ? appValues.user.address.street : null,
        sender_house_number: appValues.user.address ? appValues.user.address.house_number : null,
        sender_city: appValues.user.address ? appValues.user.address.city : null,
        sender_zip: appValues.user.address ? appValues.user.address.zip : null,
        sender_email: appValues.user.address ? appValues.user.address.email : null,
        sender_phone: appValues.user.address ? appValues.user.address.phone : null,
        sender_state_code: 'SK'
    });

    const [receiverValues, setReceiverValues] = useState( {
        parcelshop: null,
        address: null,
    });

    const [servicesValues, setServicesValues] = useState({ //actual services
        services: {}
    });

    const [additionalValues, setAdditionalValues] = useState(state ? state : {
        insurance: null,
        reverse: false,
        save_address: false
    });

    const [user, setUser] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [valuesErrors, setValuesErrors] = useState({
        services: {}
    });
    const [notAllowedServices, setNotAllowedServices] = useState(null);
    const [services, setServices] = useState([]); //all services
    const shipperObject = appValues.shippers.find((s) => s.id === parseInt(shipper));

    const [stateFrom, setStateFrom] = useState(null);
    const [statesFrom, setStatesFrom] = useState([appValues.states.find(state => state.code === 'SK')]);
    const [stateTo, setStateTo] = useState(null);
    const [statesTo, setStatesTo] = useState([]);
    const [parcelshops, setParcelshops] = useState([]);
    const [carrierTypes, setCarrierTypes] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [loader, setLoader] = useState(false);
    const [isReverse, setIsReverse] = useState(false);
    const [ reverse, setReverse ] = useState('no_reverse');
    const [carriers, setCarriers] = useState([]);

    const handleChangeSender = (event) => {
        setSenderValues({
            ...senderValues,
            [event.target.name]: event.target.value
        });
    };
    const handleChangeReceiver = (event) => {
        setReceiverValues({
            ...receiverValues,
            [event.target.name]: event.target.value
        });
    };
    const handleChangeAdditional = (event) => {
        setAdditionalValues({
            ...additionalValues,
            [event.target.name]: event.target.value
        });
    };

    const handleServiceChange = (event) => {
        let tmpServices = { ...servicesValues.services, [event.target.name]: event.target.value };
        setServicesValues({
            ...servicesValues,
            services: tmpServices
        });
    };

    const handleCheckboxChangeAdditional = (event) => {
        setAdditionalValues({ ...additionalValues, [event.target.name]: event.target.checked});
    };

    const handleServiceCheckboxChange = (event) => {
        let tmpServices = { ...servicesValues.services, [event.target.name]: !!event.target.checked };
        setServicesValues({
            ...servicesValues,
            services: tmpServices
        });
    };

    const onStateChange = async (e, state, name) => {
        if (state) {
            if (name === 'receiver_state_code') {
                let json = await secureFetch(getApiRoute('codlimit', [shipper, state.id]));
                let curr = json.map((val) => val.currency);
                setCurrencies(curr);
                setStateTo(state);
                setAdditionalValues({ ...additionalValues, 'cod_currency_code': curr[0] ? curr[0].code : additionalValues['cod_currency_code'] });
                if (shipper === '3' && !parcelshop_id) {
                    let carrierType = await secureFetch(getApiRoute('price_list_carrier', [shipper, state.id]));
                    setCarrierTypes(carrierType);
                    setReceiverValues({ ...receiverValues, [name]: state.code, carrier_type: null});
                } else {
                    setReceiverValues({ ...receiverValues, [name]: state.code});
                }
            } else {
                if (name === 'sender_state_code') {
                    setStateFrom(state);
                }
                setSenderValues({ ...senderValues, [name]: state.code });
            }
        }
    };

    const onParcelshopChange = async (e, pParcelshop, name) => {
        if (pParcelshop) {
            if (name === 'receiver_parcelshop'){
                setReceiverValues({
                    ...receiverValues,
                    parcelshop: pParcelshop.parcelshop_id,
                    receiver_company: pParcelshop.name,
                    receiver_street: pParcelshop.street,
                    receiver_city: pParcelshop.city,
                    receiver_zip: pParcelshop.zip,
                    receiver_state_code: pParcelshop.state_code,
                })
            } else {
                // there is not a sender parcelshop
            }
        }
    }

    const onCarrierTypesChange = async (e, pCarrierType) => {
        if (pCarrierType) {
            setReceiverValues({
                ...receiverValues,
                carrier_type: pCarrierType.packeta_id,
            })
        }
    }

    const setCurrenciesFirst = async (state) => {
        let json = await secureFetch(getApiRoute('codlimit', [shipper, state.id]));
        let curr = json.map((val) => val.currency);
        setCurrencies(curr);
    };

    useEffect(() => { // get not allowed services combination
        if (servicesValues.services && Object.entries(services).length !== 0 && receiverValues.receiver_state_code && senderValues.sender_state_code && !isLoading) {
            let tmpVal = {
                sender_state_code: senderValues.sender_state_code,
                receiver_state_code: receiverValues.receiver_state_code,
                insurance: additionalValues.insurance,
                reverse: additionalValues.reverse,
                parcelshop: receiverValues.parcelshop,
                services: servicesValues.services,
            };
            secureFetch(getApiRoute('package_services', [shipper]), tmpVal, 'POST' ).then(json => {
                setNotAllowedServices(json)
            });
        } else {
            setNotAllowedServices(services.map(s => s.code));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureFetch, services, servicesValues.services, shipper, senderValues.sender_state_code, receiverValues.receiver_state_code, additionalValues.insurance, additionalValues.reverse, receiverValues.parcelshop]);


    useEffect(() => { // load all services for user
        if (parcelshop_id && receiverValues.parcelshop === null) {
            return;
        }

        if (shipper && receiverValues.receiver_state_code && (user !== null || !id) ) {
            let url ='';
            if ( appValues.user.roles.includes('ROLE_USER') ) {
                url = getApiRoute('package_services', [shipper, appValues.states.find(state => state.code === receiverValues.receiver_state_code).id])
            } 
            else if (user.id) {
                url = getApiRoute('admin_package_services', [shipper, appValues.states.find(state => state.code === receiverValues.receiver_state_code).id, user.id]);
            }

            if ( url !== '' ) {
                secureFetch(url).then(json => {
                    setServices(json);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureFetch, shipper, appValues.states, receiverValues.receiver_state_code, user, appValues.user.roles, id, receiverValues.parcelshop, setServices]);


    useEffect(() => { //if edit load package data
        if (id) {
            setLoader(true);
            secureFetch(getApiRoute(appValues.user.roles.includes('ROLE_USER') ? 'package' : 'admin_package', [id])).then(json => {
                let pConditions = {
                    main_package_id: id
                }
                secureFetch(getApiRoute(appValues.user.roles.includes('ROLE_USER') ? 'package' : 'admin_package') + '?' + createUrlQuery(pConditions)).then(jsonSub => {
                    if (jsonSub.count !== 0) {
                        json.count_of_packages = jsonSub.count + 1;
                    }
                    const tmpStateFrom = appValues.states.find(s => s.code === json.sender_state_code);
                    const tmpStateTo = appValues.states.find(s => s.code === json.receiver_state_code);

                    setStateTo(tmpStateTo);
                    setStateFrom(tmpStateFrom);

                    //also carrier type first if needed
                    if (shipper === '3' && !json.parcelshop) {
                        secureFetch(getApiRoute('price_list_carrier', [shipper, tmpStateTo.id])).then(carrierType => {
                            setCarrierTypes(carrierType);
                            // setReceiverValues({ ...receiverValues, carrier_type: json.others.carrier_type});
                        });
                        secureFetch(getApiRoute('carrier_available')).then(json => {
                            setCarriers(json);
                        });
                    }

                    setUser(json.user);
                    setSenderValues({
                        ...senderValues,
                        sender_name: json.sender_name,
                        sender_company: json.sender_company,
                        sender_street: json.sender_street,
                        sender_house_number: json.sender_house_number,
                        sender_city: json.sender_city,
                        sender_zip: json.sender_zip,
                        sender_state_code: json.sender_state_code,
                        sender_email: json.sender_email,
                        sender_phone: json.sender_phone,
                        pickup_address: json.pickup_address ? json.pickup_address.id : getDefaultAddress(appValues.user).id
                    });
                    setReceiverValues({
                        ...receiverValues,
                        parcelshop: json.parcelshop,
                        receiver_name: json.receiver_name,
                        receiver_company: json.receiver_company,
                        receiver_street: json.receiver_street,
                        receiver_house_number: json.receiver_house_number,
                        receiver_city: json.receiver_city,
                        receiver_zip: json.receiver_zip,
                        receiver_state_code: json.receiver_state_code,
                        receiver_email: json.receiver_email,
                        receiver_phone: json.receiver_phone,
                        carrier_type: json.others.carrier_type ? json.others.carrier_type : null
                    });
                    setServicesValues({
                        ...servicesValues,
                        services: json.services
                    });
                    setAdditionalValues({
                        ...additionalValues,
                        reference_number: json.reference_number,
                        count_of_packages: json.count_of_packages,
                        cod_price: json.cod_price,
                        cod_currency_code: json.cod_currency_code,
                        cod_reference: json.cod_reference,
                        insurance: json.insurance,
                        reverse: json.reverse,
                        weight: json.weight
                    });
                    setCurrenciesFirst(tmpStateTo);
                    setIsReverse(json.reverse);
                    setReverse(json.reverse ? ( json.sender_state_code === 'SK' ? 'from_sk' : 'to_sk' ) : 'no_reverse')
                    if(json.parcelshop){
                        secureFetch(getApiRoute( 'nearest_parcelshops', [shipper, json.parcelshop])).then(parcelshopsJson => {
                            setParcelshops(parcelshopsJson);
                            setIsLoading(false);
                            setLoader(false);
                        });
                    } else {
                        setIsLoading(false);
                        setLoader(false);
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureFetch, id, appValues.user.roles, isLoading]);

    useEffect(() => {
        if (shipper !== '3' || !parcelshop_id || (!address_id && !isLoading)) {
            if (isReverse) {
                if (reverse === 'from_sk') {
                    secureFetch(getApiRoute('price_list_states', ['to', shipper])).then(json => {
                        setStatesFrom(json);
                        setStatesTo(appValues.states.filter(s => s.code === 'SK'));
                    })
                } else {
                    secureFetch(getApiRoute('price_list_states', ['from', shipper])).then(json => {
                        setStatesTo(json);
                        setStatesFrom(appValues.states.filter(s => s.code === 'SK'));
                    })
                }
            } else {
                secureFetch(getApiRoute('price_list_states', ['to', shipper])).then(json => {
                    const tmpState = json.find(s => s.code === receiverValues.receiver_state_code);
                    setStatesTo(json);
                    onStateChange(null, tmpState, 'receiver_state_code');
                })
            }
        }
        // if (shipper === '3'){
        //     secureFetch(getApiRoute('price_list_carrier', [shipper])).then(json => {
        //         console.log(json)
        //         setCarrierTypes(json);
        //     })
        // }
        // secureFetch(getApiRoute('price_list_states', ['from', shipper])).then(json => {
        //     const tmpState = json.find(s => s.code === 'SK');
        //     setStatesFrom(json);
        //     onStateChange(null, tmpState, 'sender_state_code');
        // })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secureFetch, shipper, isReverse])


    const removeRedundantFields = (data) => {
        delete data.id;
        delete data.statuses;
        delete data.user;
        delete data.last_status;
        delete data.cod_payment;
        delete data.tracking_number;
        delete data.shipper;
        delete data.last_status;
        delete data.total_weight;
        delete data.packages;
        delete data.others;
        if ( shipperObject.shortcut && shipperObject.shortcut.toUpperCase() !== 'PACKETA') {
            if ( shipperObject.shortcut && shipperObject.shortcut.toUpperCase() === 'DPD') {
                delete data.carrier_type;
            } else {
                delete data.carrier_type;
                delete data.weight;
            }
        }

        for (const key in data.services) {
            const serviceDefinition = services.find(s => s.code === key.replace('value', ''));
            if( !serviceDefinition ) {
                delete data.services[key];
                delete data.services[key + 'value'];
            } else if ( !serviceDefinition.input_type ){
                delete data.services[key + 'value'];
            }
        }

        return data;
    }
    
    const handleFormSubmit = (event) => {
        event.preventDefault();
        let values = {...senderValues, ...receiverValues, ...additionalValues, ...servicesValues, source: 'WEB'.toUpperCase()};
        secureFetch(getApiRoute(appValues.user.roles.includes('ROLE_USER') ? 'package' : 'admin_package', [id]), removeRedundantFields(values), 'PUT', false).then(json => {
            const url = new URL(window.location);
            const next = url.searchParams.get("next");
            if (next === 'close') {
                window.close();
            }
            const uspl = appValues.user.user_shipper_price_lists.find(uspl => uspl.shipper.id === parseInt(shipper));
            navigate(uspl.sale_price_list.type === 'fix' ? '/app/package/service' : '/app/package');
        }).catch( (json) => {
            setValuesErrors(json);
        } );
    }

    return (
        <Page
            title={id ? t('title.edit_package') : t('title.create_package')}
        >
            <Container maxWidth={false}>
                <Typography variant="h1" gutterBottom >
                    {id ? t('title.edit_package') : t('title.create_package')}
                </Typography>
                <form onSubmit={handleFormSubmit} noValidate>
                    <Grid container spacing={3}>
                        {
                            shipperObject.shortcut && shipperObject.shortcut.toUpperCase() !== 'PACKETA' &&
                            <Grid item xs={12} sm={6} md={4} >
                                <AddressForm
                                    onStateChange={onStateChange}
                                    states={statesFrom}
                                    state={stateFrom}
                                    id={id}
                                    prefix="sender_"
                                    title={t('title.sender_address')}
                                    values={senderValues}
                                    setValues={setSenderValues}
                                    handleChange={handleChangeSender}
                                    errors={ valuesErrors }
                                    pickupAddress={senderValues.pickup_address ? `ID: ${senderValues.pickup_address}` : null}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} sm={6} md={4}>
                            <AddressForm
                                onStateChange={onStateChange}
                                states={statesTo}
                                state={stateTo}
                                id={id}
                                prefix="receiver_"
                                title={t('title.receiver_address')}
                                values={receiverValues}
                                setValues={setReceiverValues}
                                handleChange={handleChangeReceiver}
                                errors={ valuesErrors }
                                parcelshop={ receiverValues.parcelshop }
                                parcelshops={parcelshops}
                                onParcelshopChange={onParcelshopChange}
                                carrierType={ receiverValues.carrier_type }
                                carrierTypes={carrierTypes}
                                onCarrierTypesChange={onCarrierTypesChange}
                                shipper={shipperObject}
                                carriers={carriers}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <AdditionalnfoForm
                                currencies={currencies}
                                title={t('title.additiona_info')}
                                values={additionalValues}
                                setValues={setAdditionalValues}
                                handleChange={handleChangeAdditional}
                                handleCheckboxChange={handleCheckboxChangeAdditional}
                                errors={ valuesErrors }
                                notAllowedServices={notAllowedServices !== null ? notAllowedServices : []}
                                shipper={shipperObject}
                                id={id}
                                senderValues={senderValues}
                                setSenderValues={setSenderValues}
                                receiverValues={receiverValues}
                                setReceiverValues={setReceiverValues}
                                statesTo={statesTo}
                                setStatesTo={setStatesTo}
                                statesFrom={statesFrom}
                                setStatesFrom={setStatesFrom}
                                stateTo={stateTo}
                                setStateTo={setStateTo}
                                stateFrom={stateFrom}
                                setStateFrom={setStateFrom}
                                setCurrencies={setCurrencies}
                                reverse={reverse}
                                setReverse={setReverse}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ServicesForm
                                title={t('title.services')}
                                services={services}
                                values={servicesValues.services}
                                handleChange={handleServiceChange}
                                notAllowedServices={notAllowedServices !== null ? notAllowedServices : []}
                                handleCheckBoxChange={handleServiceCheckboxChange}
                                errors={ valuesErrors.services }
                            />
                        </Grid>
                    </Grid>
                </form>
                <LoadingOverlay
                    active={loader}
                    spinner
                    styles={{
                        wrapper: {position: 'static'}
                    }}
                    classNamePrefix='ServiceFormLoader_'
                    text={<Typography variant='subtitle1'>{t('messages.loading_content')}</Typography> }
                >
                </LoadingOverlay>
            </Container>
        </Page>
    );
}