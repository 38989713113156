import React, {useEffect, useContext, useState, useCallback} from 'react';
import {
    Grid,
    Container,
    Card,
    CardContent,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import getApiRoute from 'routes/apiRoutes';
import SecureFetchContext from 'context/SecureFetchContext';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import PricesTabs from './PricesTabs';
import AppContext from 'context/AppContext';
import Page from 'components/Page';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    tabPanel: {
        '& > .MuiBox-root': {
            paddingTop: 0
        }
    }
}));

export default function UserPrices({ user, ...rest }) {
    const { appValues } = useContext(AppContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const secureFetch = useContext(SecureFetchContext);
    const [prices, setPrices] = React.useState([]);
    const [selectedPriceList, setSelectedPriceList] = React.useState( appValues.user.user_shipper_price_lists.length ? appValues.user.user_shipper_price_lists[0] : null );
    const [carriers, setCarriers] = useState([]);

    useEffect(() => {
        if (selectedPriceList.id && selectedPriceList.shipper.shortcut === 'Packeta' && carriers.length === 0) {
            secureFetch(getApiRoute('carrier_available')).then(json => {
                setCarriers(json);
            });
        }
    }, [selectedPriceList]);


    useEffect(() => {
        secureFetch(getApiRoute('price_list', [selectedPriceList.shipper.id])).then(json => {
            setPrices(json);
            for (let price of json.prices) {
                price.delivery_type = appValues.deliveryTypes.find((el) => el.value === price.delivery_type).name;
            }
            for (let price of json.cod_prices) {
                price.delivery_type = appValues.deliveryTypes.find((el) => el.value === price.delivery_type).name;
                price.payment_type = appValues.paymentTypes.find((el) => el.value === price.payment_type).name;
            }
        });
    }, [secureFetch, appValues.user, selectedPriceList, appValues.deliveryTypes, appValues.paymentTypes]);

    useEffect(() => {
        if (prices.length !== 0 && carriers.length !== 0 && selectedPriceList.shipper.shortcut === 'Packeta') {
            for (let price of prices.prices) {
                if (price.carrier_type !== null) {
                    let packetaCarrier = carriers.find(option => option.packeta_id === price.carrier_type);
                    if (packetaCarrier) {
                        price.carrier_type = packetaCarrier.name;
                    }
                }
            }
            for (let price of prices.cod_prices) {
                let packetaCarrier = carriers.find(option => option.packeta_id === price.carrier_type);
                if (packetaCarrier) {
                    price.carrier_type = packetaCarrier.name;
                }
            }
            for (let price of prices.insurance_prices) {
                let packetaCarrier = carriers.find(option => option.packeta_id === price.carrier_type);
                if (packetaCarrier) {
                    price.carrier_type = packetaCarrier.name;
                }
            }
        }

    }, [prices, carriers]);

    return (
        <Page
            className={classes.root}
            title={t('title.pricelist')}
        >
            <Container maxWidth={false}>
                <Card>
                    <CardContent>
                        <Grid container spacing={3} alignItems="center" >
                            <Grid item lg={4} md={6} xs={12}>
                                <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel id="shipper">{t('label.shipper')}</InputLabel>
                                    <Select
                                        labelId="shipper"
                                        value={selectedPriceList ? appValues.user.user_shipper_price_lists.indexOf(selectedPriceList) : ''}
                                        onChange={(event) => setSelectedPriceList(appValues.user.user_shipper_price_lists[event.target.value])}
                                        name="shipper"
                                    >
                                        {appValues.user.user_shipper_price_lists.map((option, index) => (
                                            <MenuItem
                                                key={option.id}
                                                value={index}
                                            >
                                                {option.shipper.shortcut}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                selectedPriceList.sale_price_list.type === 'fix' &&
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        {parseFloat(selectedPriceList.sale_price_list.price).toFixed(2)} € {t('messages.per_package')}
                                    </Typography> 
                                </Grid>
                            }
                        </Grid>
                    </CardContent>
                </Card>
                {selectedPriceList.sale_price_list.type !== 'fix' &&
                    <PricesTabs prices={prices} shipper={selectedPriceList.shipper} tab={'shipping_price'}/>
                }
            </Container>
        </Page>

    );
}